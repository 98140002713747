<template>
  <div class="stake">
    <b-row class="stake-statistic" v-if="StatisticStake">
      <b-col cols="12" sm="12" md="6" lg="6" xl="3" class="mb-3">
        <div class="stake-statistic-item">
          <p>{{ $t('wallet.balance') }} Stake</p>
          <hr />
          <div class="amount">
            <p>{{ TruncateToDecimals2(StatisticStake.balanceStake, '', 2) }}</p>
            <img src="~@/assets/images/currency/usd.png" alt="" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="6" lg="6" xl="3" class="mb-3">
        <div class="stake-statistic-item">
          <p>{{ $t('expert.Total') }} Stake</p>
          <hr />
          <div class="amount">
            <p>{{ TruncateToDecimals2(StatisticStake.totalInvest, '', 2) }}</p>
            <img src="~@/assets/images/currency/usd.png" alt="" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="6" lg="6" xl="3" class="mb-3">
        <div class="stake-statistic-item">
          <p>{{ $t('agency.total_commission') }}</p>
          <hr />
          <div class="amount">
            <p>
              {{ TruncateToDecimals2(StatisticStake.totalCommission, '', 2) }}
            </p>
            <img src="~@/assets/images/currency/usd.png" alt="" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="6" lg="6" xl="3" class="mb-3">
        <div class="stake-statistic-item">
          <p>{{ $t('stake.profitReceived') }}</p>
          <hr />
          <div class="amount">
            <p>
              {{ TruncateToDecimals2(StatisticStake.profitReceived, '', 2) }}
            </p>
            <img src="~@/assets/images/currency/usd.png" alt="" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="6" lg="6" xl="3" class="mb-3">
        <div class="stake-statistic-item">
          <p>{{ $t('stake.pendingReward') }}</p>
          <hr />
          <div class="amount">
            <p>
              {{ TruncateToDecimals2(StatisticStake.pendingReward, '', 2) }}
            </p>
            <img src="~@/assets/images/currency/usd.png" alt="" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="6" lg="6" xl="3" class="mb-3">
        <div class="stake-statistic-item">
          <p>{{ $t('expert.Total') }} {{ $t('stake.bonus') }}</p>
          <hr />
          <div class="amount">
            <p>
              {{ TruncateToDecimals2(StatisticStake.totalBonus, '', 2) }}
            </p>
            <img src="~@/assets/images/currency/usd.png" alt="" />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="stake-level mt-5">
      <b-col xl="6" class="mb-3">
        <div class="stake-level-item package">
          <div class="notification" @click="$bvModal.show('info-package')">
            <img src="~@/assets/images/icons/icon-notification.png" alt="" />
          </div>
          <template v-if="PackageUser">
            <div class="stake-level-item-img">
              <img
                :src="
                  require(`@/assets/images/stake-level/${PackageUser.package_Name.toLowerCase()}.png`)
                "
                alt=""
              />
            </div>
            <div class="stake-level-item-info">
              <h3>{{ PackageUser.package_Description }}</h3>
              <p class="duration">
                {{ $t('stake.duration') }}: {{ PackageUser.package_Date }}
                {{ $t('promotion_insurance.days') }}
              </p>
              <div class="box-pending">
                <div class="box-pending-stake">
                  <p>
                    <img
                      src="~@/assets/images/icons/pending-stake.png"
                      alt=""
                    />
                    Stake
                  </p>
                  <p class="amount">
                    {{
                      TruncateToDecimals2(+StatisticStake.balanceStake, '', 2)
                    }}
                    USD
                  </p>
                </div>
                <div class="line"></div>
                <div class="box-pending-reward">
                  <p>
                    <img
                      src="~@/assets/images/icons/pending-reward.png"
                      alt=""
                    />
                    {{ $t('stake.pendingReward') }}
                  </p>
                  <p class="amount">
                    {{
                      TruncateToDecimals2(+StatisticStake.pendingReward, '', 2)
                    }}
                    USD
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <b-button
                  class="btnNoStyle btn-recharge"
                  @click="$bvModal.show('deposit')"
                  >{{ $t('stake.depositGame') }}</b-button
                >
                <b-button
                  v-if="false"
                  class="btnNoStyle btn-recharge"
                  @click="$bvModal.show('withdraw')"
                  >Withdraw</b-button
                >
              </div>
            </div>
          </template>
          <h3 class="text-center w-100 mb-0" v-else>You haven't joined yet</h3>
        </div>
      </b-col>
      <b-col xl="6" class="mb-3">
        <div class="stake-level-item form-stake">
          <div class="header">
            <p class="minimum">
              {{ $t('stake.minimum') }} Stake:
              <span
                >{{
                  ListPackage && ListPackage.length > 0
                    ? TruncateToDecimals2(
                        +ListPackage[0].package_Price_Min,
                        '',
                        2
                      )
                    : 0
                }}
                USD</span
              >
            </p>
            <p class="amount">
              {{ $t('stake.amountBalance') }}:
              {{ TruncateToDecimals2(+UserBalance.MainBalance, '', 2) }}
            </p>
          </div>
          <div class="box-search">
            <p>{{ $t('wallet.amount') }}</p>
            <input
              @change="checkMinAmount"
              type="number"
              class="custom-input w-100"
              v-model="amountStake"
            />
            <b-button
              class="btnNoStyle"
              @click="amountStake = +UserBalance.MainBalance"
              >USD <span class="line"></span> <span>MAX</span></b-button
            >
          </div>
          <b-button
            v-if="
              StatisticStake &&
              StatisticStake.packageList &&
              StatisticStake.packageList.length > 0 &&
              (!UserStake || UserStake.length === 0)
            "
            class="btnNoStyle btn-stake"
            @click="$bvModal.show('confirm-stake')"
            :disabled="
              +amountStake < +StatisticStake.packageList[0].package_Price_Min
            "
            >Stake</b-button
          >
          <b-button
            v-else
            class="btnNoStyle btn-stake"
            @click="onShowModalUpgradePackage(UserStake[0].investment_ID)"
            >{{ $t('stake.upgrade') }}</b-button
          >
        </div>
      </b-col>
    </b-row>
    <div class="stake-history">
      <div class="historyTable">
        <div class="stake-tab">
          <div class="listTab">
            <b-button
              class="btnNoStyle tab-item"
              :class="activeTab === 'my-stake' ? 'activeTab' : ''"
              @click="onActiveTab('my-stake')"
              >Stake</b-button
            >
            <b-button
              class="btnNoStyle tab-item"
              :class="activeTab === 'stake' ? 'activeTab' : ''"
              @click="onActiveTab('stake')"
              >{{ $t('exchange.history') }} Stake</b-button
            >
            <b-button
              class="btnNoStyle tab-item"
              :class="activeTab === 'profit' ? 'activeTab' : ''"
              @click="onActiveTab('profit')"
              >{{ $t('stake.historyProfit') }}</b-button
            >
            <b-button
              class="btnNoStyle tab-item"
              :class="activeTab === 'game' ? 'activeTab' : ''"
              @click="onActiveTab('game')"
              >{{ $t('stake.depositGame') }}</b-button
            >
            <b-button
              class="btnNoStyle tab-item"
              :class="activeTab === 'bonus' ? 'activeTab' : ''"
              @click="onActiveTab('bonus')"
              >{{ $t('exchange.history') }} {{ $t('stake.bonus') }}</b-button
            >
          </div>
        </div>
        <!-- List Stake -->
        <template v-if="activeTab === 'stake'">
          <div class="table-responsive myTable" v-if="!showTableMobile">
            <b-table
              :items="ListStake.data"
              :fields="fieldsStake"
              show-empty
              class="customTable"
            >
              <template #empty="">
                <h5 class="text-center p-2">
                  {{ $t('support.no_data_available_in_table') }}
                </h5>
              </template>
              <template #cell(investment_Total)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ TruncateToDecimals2(+data.item.investment_Total, '', 2) }}
                </div>
              </template>
              <template #cell(investment_Bonus)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ TruncateToDecimals2(+data.item.investment_Bonus, '', 2) }}
                </div>
              </template>
              <template #cell(investment_Rate)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ TruncateToDecimals2(+data.item.investment_Rate, '', 2) }}
                </div>
              </template>
              <template #cell(investment_Expired)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ getDateTime(+data.item.investment_Expired * 1000) }}
                </div>
              </template>
              <template #cell(investment_Time)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ getDateTime(+data.item.investment_Time * 1000) }}
                </div>
              </template>
              <template #cell(investment_Status)="data">
                <div class="d-flex align-items-center justify-content-center">
                  <p
                    class="mb-0 text-center"
                    :class="
                      data.item.investment_Status === 1
                        ? 'text-success'
                        : data.item.investment_Status === 2
                        ? 'text-warning'
                        : 'text-danger'
                    "
                  >
                    {{
                      data.item.investment_Status === 1
                        ? 'Success'
                        : data.item.investment_Status === 2
                        ? 'Pending'
                        : 'Cancel'
                    }}
                  </p>
                </div>
              </template>
            </b-table>
          </div>
          <div class="table-mobile" v-else>
            <template
              v-if="ListStake && ListStake.data && ListStake.data.length > 0"
            >
              <div
                v-for="(history, idx) in ListStake.data"
                :key="'listStakeHistory: ' + history.idx"
                class="w-100"
                role="tablist"
              >
                <b-button
                  v-b-toggle="`listStakeHistory-${idx}`"
                  class="btnNoStyle header"
                  role="tab"
                >
                  Stake -
                  {{ TruncateToDecimals2(+history.investment_Total, '', 2) }} -
                  {{ getDateTime(+history.investment_Time * 1000) }}</b-button
                >
                <b-collapse
                  :id="`listStakeHistory-${idx}`"
                  class="mt-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <p>- Code: {{ history.investment_ID }}</p>
                  <p>
                    - Amount:
                    {{ TruncateToDecimals2(+history.investment_Total, '', 2) }}
                  </p>
                  <p>
                    - Bonus:
                    {{ TruncateToDecimals2(+history.investment_Bonus, '', 2) }}
                  </p>
                  <p>
                    - Expired:
                    {{ getDateTime(+history.investment_Expired * 1000) }}
                  </p>
                  <p>
                    - Rate:
                    {{ TruncateToDecimals2(+history.investment_Rate, '', 2) }}
                  </p>
                  <p>
                    - Time: {{ getDateTime(+history.investment_Time * 1000) }}
                  </p>
                  <p>
                    - Status:
                    <span
                      :class="
                        history.investment_Status === 1
                          ? 'text-success'
                          : history.investment_Status === 2
                          ? 'text-warning'
                          : 'text-danger'
                      "
                    >
                      {{
                        history.investment_Status === 1
                          ? 'Success'
                          : history.investment_Status === 2
                          ? 'Pending'
                          : 'Cancel'
                      }}
                    </span>
                  </p>
                </b-collapse>
              </div>
            </template>
            <p v-else class="text-center">
              {{ $t('support.no_data_available_in_table') }}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-end align-items-center">
            <paginate
              :totalPage="ListStake.last_page"
              :perPage="ListStake.current_page"
              @current="onChangePageListStake"
            />
          </div>
        </template>
        <!-- End List Stake -->
        <!-- My Stake -->
        <template v-if="activeTab === 'my-stake'">
          <div class="table-responsive myTable" v-if="!showTableMobile">
            <b-table
              :items="UserStake"
              :fields="fieldsStake"
              show-empty
              class="customTable"
            >
              <template #empty="">
                <h5 class="text-center p-2">
                  {{ $t('support.no_data_available_in_table') }}
                </h5>
              </template>
              <template #cell(investment_Total)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ TruncateToDecimals2(+data.item.investment_Total, '', 2) }}
                </div>
              </template>
              <template #cell(investment_Bonus)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ TruncateToDecimals2(+data.item.investment_Bonus, '', 2) }}
                </div>
              </template>
              <template #cell(investment_Rate)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ TruncateToDecimals2(+data.item.investment_Rate, '', 2) }}
                </div>
              </template>
              <template #cell(investment_Expired)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ getDateTime(+data.item.investment_Expired * 1000) }}
                </div>
              </template>
              <template #cell(investment_Time)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ getDateTime(+data.item.investment_Time * 1000) }}
                </div>
              </template>
              <template #cell(investment_Status)="data">
                <div class="d-flex align-items-center justify-content-center">
                  <p
                    class="mb-0 text-center"
                    :class="
                      data.item.investment_Status === 1
                        ? 'text-success'
                        : data.item.investment_Status === 2
                        ? 'text-warning'
                        : 'text-danger'
                    "
                  >
                    {{
                      data.item.investment_Status === 1
                        ? 'Success'
                        : data.item.investment_Status === 2
                        ? 'Pending'
                        : 'Cancel'
                    }}
                  </p>
                </div>
              </template>
              <template #cell(action)="data">
                <div
                  class="d-flex align-items-center justify-content-center flex-column"
                >
                  <b-button
                    class="w-100 btn-upgrade"
                    @click="onShowModalUpgradePackage(data.item.investment_ID)"
                    >{{ $t('stake.upgrade') }}</b-button
                  >
                </div>
              </template>
            </b-table>
          </div>
          <div class="table-mobile" v-else>
            <template v-if="UserStake && UserStake.length > 0">
              <div
                v-for="(history, idx) in UserStake"
                :key="'myStake: ' + history.idx"
                class="w-100"
                role="tablist"
              >
                <b-button
                  v-b-toggle="`myStake-${idx}`"
                  class="btnNoStyle header"
                  role="tab"
                >
                  Stake -
                  {{ TruncateToDecimals2(+history.investment_Total, '', 2) }} -
                  {{ getDateTime(+history.investment_Time * 1000) }}</b-button
                >
                <b-collapse
                  :id="`myStake-${idx}`"
                  class="mt-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <p>- Code: {{ history.investment_ID }}</p>
                  <p>
                    - Amount:
                    {{ TruncateToDecimals2(+history.investment_Total, '', 2) }}
                  </p>
                  <p>
                    - Bonus:
                    {{ TruncateToDecimals2(+history.investment_Bonus, '', 2) }}
                  </p>
                  <p>
                    - Expired:
                    {{ getDateTime(+history.investment_Expired * 1000) }}
                  </p>
                  <p>
                    - Rate:
                    {{ TruncateToDecimals2(+history.investment_Rate, '', 2) }}
                  </p>
                  <p>
                    - Time: {{ getDateTime(+history.investment_Time * 1000) }}
                  </p>
                  <p>
                    - Status:
                    <span
                      :class="
                        history.investment_Status === 1
                          ? 'text-success'
                          : history.investment_Status === 2
                          ? 'text-warning'
                          : 'text-danger'
                      "
                    >
                      {{
                        history.investment_Status === 1
                          ? 'Success'
                          : history.investment_Status === 2
                          ? 'Pending'
                          : 'Cancel'
                      }}
                    </span>
                  </p>
                  <p>
                    - Action:
                    <b-button
                      class="btn-upgrade w-50"
                      @click="onShowModalUpgradePackage(history.investment_ID)"
                      >{{ $t('stake.upgrade') }}</b-button
                    >
                  </p>
                </b-collapse>
              </div>
            </template>
            <p v-else class="text-center">
              {{ $t('support.no_data_available_in_table') }}
            </p>
          </div>
        </template>
        <!-- End My Stake -->
        <!-- History -->
        <template v-if="activeTab === 'game' || activeTab === 'profit'">
          <div class="table-responsive myTable" v-if="!showTableMobile">
            <b-table
              :items="HistoryProfitOrGameShow"
              :fields="fieldsHistory"
              show-empty
              class="customTable"
            >
              <template #empty="">
                <h5 class="text-center p-2">
                  {{ $t('support.no_data_available_in_table') }}
                </h5>
              </template>
              <template #cell(Money_CurrentAmount)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{
                    TruncateToDecimals2(+data.item.Money_CurrentAmount, '', 2)
                  }}
                </div>
              </template>
              <template #cell(Money_USDT)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ TruncateToDecimals2(+data.item.Money_USDT, '', 2) }}
                </div>
              </template>
              <template #cell(Money_Rate)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ TruncateToDecimals2(+data.item.Money_Rate, '', 2) }}
                </div>
              </template>
              <template #cell(Money_USDTFee)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ TruncateToDecimals2(+data.item.Money_USDTFee, '', 2) }}
                </div>
              </template>
              <template #cell(Money_Time)="data">
                {{ getDateTime(data.value * 1000, 'DD/MM/YYYY') }}
              </template>
            </b-table>
          </div>
          <div class="table-mobile" v-else>
            <template
              v-if="
                HistoryStake &&
                HistoryStake.data &&
                HistoryStake.data.length > 0
              "
            >
              <div
                v-for="(history, idx) in HistoryStake.data"
                :key="'profitHistory: ' + history.id"
                class="w-100"
                role="tablist"
              >
                <b-button
                  v-b-toggle="`profitHistory-${idx}`"
                  class="btnNoStyle header"
                  role="tab"
                  >{{ history.MoneyAction_Name }} -
                  {{ TruncateToDecimals2(+history.Money_CurrentAmount, '', 2) }}
                  -
                  {{
                    getDateTime(history.Money_Time * 1000, 'DD/MM/YYYY')
                  }}</b-button
                >
                <b-collapse
                  :id="`profitHistory-${idx}`"
                  class="mt-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <p>- ID: {{ history.Money_ID }}</p>
                  <p>- Action: {{ history.MoneyAction_Name }}</p>
                  <p>
                    - Amount:
                    {{ TruncateToDecimals2(+history.Money_USDT, '', 2) }}
                  </p>
                  <p>- Comment: {{ history.Money_Comment }}</p>
                  <p>
                    - Rate:
                    {{ TruncateToDecimals2(+history.Money_Rate, '', 2) }}
                  </p>
                  <p>
                    - Fee:
                    {{ TruncateToDecimals2(+history.Money_USDTFee, '', 2) }}
                  </p>
                  <p>
                    - Time:
                    {{ getDateTime(history.Money_Time * 1000, 'DD/MM/YYYY') }}
                  </p>
                </b-collapse>
              </div>
            </template>
            <p v-else class="text-center">
              {{ $t('support.no_data_available_in_table') }}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-end align-items-center">
            <paginate
              :totalPage="
                HistoryStake && HistoryStake.last_page >= 1
                  ? HistoryStake.last_page
                  : 1
              "
              :perPage="
                HistoryStake && HistoryStake.current_page >= 1
                  ? HistoryStake.current_page
                  : 1
              "
              @current="onChangePageHistory"
            />
          </div>
        </template>
        <!-- End History -->
        <!-- History Bonus -->
        <template v-if="activeTab === 'bonus'">
          <div class="table-responsive myTable" v-if="!showTableMobile">
            <b-table
              :items="HistoryBonus.data"
              :fields="fieldsBonus"
              show-empty
              class="customTable"
            >
              <template #empty="">
                <h5 class="text-center p-2">
                  {{ $t('support.no_data_available_in_table') }}
                </h5>
              </template>
              <template #cell(Log_Amount)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ TruncateToDecimals2(+data.item.Log_Amount, '', 2) }}
                </div>
              </template>
              <template #cell(Log_CreatedAt)="data">
                <div class="d-flex align-items-center justify-content-center">
                  {{ getDateTime(data.item.Log_CreatedAt) }}
                </div>
              </template>
              <template #cell(Log_Status)="data">
                <div class="d-flex align-items-center justify-content-center">
                  <p
                    class="mb-0 text-center"
                    :class="
                      data.item.Log_Status === 1
                        ? 'text-success'
                        : data.item.Log_Status === 2
                        ? 'text-warning'
                        : 'text-danger'
                    "
                  >
                    {{
                      data.item.Log_Status === 1
                        ? 'Success'
                        : data.item.Log_Status === 2
                        ? 'Pending'
                        : 'Cancel'
                    }}
                  </p>
                </div>
              </template>
            </b-table>
          </div>
          <div class="table-mobile" v-else>
            <template
              v-if="
                HistoryBonus &&
                HistoryBonus.data &&
                HistoryBonus.data.length > 0
              "
            >
              <div
                v-for="(history, idx) in HistoryBonus.data"
                :key="'listStakeHistory: ' + history.idx"
                class="w-100"
                role="tablist"
              >
                <b-button
                  v-b-toggle="`listStakeHistory-${idx}`"
                  class="btnNoStyle header"
                  role="tab"
                >
                  {{ $t('stake.bonus') }} -
                  {{ TruncateToDecimals2(+history.Log_Amount, '', 2) }} -
                  {{ history.Log_CreatedAt }}</b-button
                >
                <b-collapse
                  :id="`listStakeHistory-${idx}`"
                  class="mt-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <p>- ID {{ $t('stake.bonus') }}: {{ history.Log_ID }}</p>
                  <p>- {{ $t('wallet.action') }}: {{ history.Log_Action }}</p>
                  <p>
                    - {{ $t('wallet.amount') }}:
                    {{ TruncateToDecimals2(+history.Log_Amount, '', 2) }}
                  </p>
                  <p>
                    - {{ $t('wallet.comment') }}:
                    {{ history.Log_Comment }}
                  </p>
                  <p>- Time: {{ getDateTime(history.Log_CreatedAt) }}</p>
                  <p>
                    - Status:
                    <span
                      :class="
                        history.Log_Status === 1
                          ? 'text-success'
                          : history.Log_Status === 2
                          ? 'text-warning'
                          : 'text-danger'
                      "
                    >
                      {{
                        history.Log_Status === 1
                          ? 'Success'
                          : history.Log_Status === 2
                          ? 'Pending'
                          : 'Cancel'
                      }}
                    </span>
                  </p>
                </b-collapse>
              </div>
            </template>
            <p v-else class="text-center">
              {{ $t('support.no_data_available_in_table') }}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-end align-items-center">
            <paginate
              :totalPage="HistoryBonus.last_page"
              :perPage="HistoryBonus.current_page"
              @current="onChangePageHistoryBonus"
            />
          </div>
        </template>
        <!-- End History Bonus -->
      </div>
    </div>
    <!-- Modal Upgrade -->
    <b-modal id="upgrade-package" hide-footer hide-header>
      <div class="header-modal">
        <h3>Upgrade Package Stake With ID: {{ idPackageChoose }}</h3>
        <img
          :src="require('@/assets/images/icons/close-white.svg')"
          alt=""
          @click="$bvModal.hide('upgrade-package')"
          class="close"
        />
      </div>
      <div class="form-group mt-4">
        <label for="">Balance</label>
        <b-form-input
          disabled
          :value="+UserBalance.MainBalance"
          class="custom-input"
        ></b-form-input>
      </div>
      <div class="form-group mt-4">
        <label for="">ID Package</label>
        <b-form-input
          disabled
          :value="idPackageChoose"
          class="custom-input"
        ></b-form-input>
      </div>
      <div class="form-group mt-4">
        <label for="">Min Amount Upgrade</label>
        <b-form-input
          disabled
          :value="
            ListPackage && ListPackage.length > 0
              ? TruncateToDecimals2(+ListPackage[0].package_Price_Min, '', 2)
              : 0
          "
          class="custom-input"
        ></b-form-input>
      </div>
      <div class="form-group mt-4">
        <label for="">Amount</label>
        <input
          type="number"
          v-model="amountUpgrade"
          class="custom-input"
        />
      </div>
      <b-button
        class="btn-upgrade mt-3"
        @click="onUpgradePackage"
        :disabled="
          !amountUpgrade || amountUpgrade < +ListPackage[0].package_Price_Min
        "
        >{{ $t('stake.upgrade') }}</b-button
      >
    </b-modal>
    <!-- End Modal  Upgrade -->
    <!-- Modal Info Page Stake-->
    <b-modal id="info-package" hide-footer hide-header size="lg">
      <div class="header-modal">
        <h3 class="mb-0">{{ $t('stake.infoPackage') }} Stake</h3>
        <img
          :src="require('@/assets/images/icons/close-white.svg')"
          alt=""
          @click="$bvModal.hide('info-package')"
          class="close"
        />
      </div>
      <div class="table-responsive" v-if="!showTableMobile">
        <b-table
          :items="ListPackage"
          :fields="fieldsInfoPackage"
          show-empty
          class="customTable"
        >
          <template #empty="">
            <h5 class="text-center p-2">
              {{ $t('support.no_data_available_in_table') }}
            </h5>
          </template>
          <template #cell(package)="data">
            <img
              :src="
                require(`@/assets/images/stake-level/${data.item.package_Name.toLowerCase()}.png`)
              "
              alt=""
              width="50px"
            />
          </template>
          <template #cell(package_Price_Max)="data">
            ${{ TruncateToDecimals2(+data.item.package_Price_Max, '', 2) }}
          </template>
          <template #cell(package_Price_Min)="data">
            ${{ TruncateToDecimals2(+data.item.package_Price_Min, '', 2) }}
          </template>
        </b-table>
      </div>
      <div class="table-mobile" v-else>
        <div
          v-for="(history, idx) in ListPackage"
          :key="'followHistory: ' + history.idx"
          class="w-100"
          role="tablist"
        >
          <b-button
            v-b-toggle="`profitHistory-${idx}`"
            class="btnNoStyle header text-capitalize"
            role="tab"
            >{{ history.package_Name }}</b-button
          >
          <b-collapse
            :id="`profitHistory-${idx}`"
            class="mt-2"
            accordion="my-accordion"
            role="tabpanel"
          >
            <p>- STT: {{ TruncateToDecimals2(history.idx + 1, '', 2) }}</p>
            <p>
              - Name:
              <span class="text-capitalize">{{ history.package_Name }}</span>
            </p>
            <p>- Description: {{ history.package_Description }}</p>
            <p>
              - Min: ${{
                TruncateToDecimals2(+history.package_Price_Min, '', 2)
              }}
            </p>
            <p>
              - Max: ${{
                TruncateToDecimals2(+history.package_Price_Max, '', 2)
              }}
            </p>
            <p>- Percent: {{ history.package_Note }}</p>
            <p>
              - Package:
              <img
                :src="
                  require(`@/assets/images/stake-level/${history.package_Name.toLowerCase()}.png`)
                "
                alt=""
                width="50px"
              />
            </p>
          </b-collapse>
        </div>
      </div>
    </b-modal>
    <!-- End Modal Info Page Stake-->
    <!-- Modal Confirm Stake-->
    <b-modal id="confirm-stake" hide-footer hide-header>
      <div class="header-modal">
        <h3 class="mb-0">{{ $t('stake.confirm') }} Stake</h3>
        <img
          :src="require('@/assets/images/icons/close-white.svg')"
          alt=""
          @click="$bvModal.hide('confirm-stake')"
          class="close"
        />
      </div>
      <p class="text-center my-3">
        {{ $t('stake.areYouSureYouWillUse') }}
        <span class="font-weight-bold text-warning"> ${{ amountStake }} </span>
        {{ $t('stake.toParticipateInThe') }}
        <span class="font-weight-bold text-warning"
          >staking {{ $t('stake.program') }}</span
        >
        ?
      </p>
      <div class="w-100 d-flex justify-content-center">
        <b-button class="w-50 btn-upgrade" @click="onStake">Stake</b-button>
      </div>
    </b-modal>
    <!-- End Modal Confirm Stake-->
    <!-- MODAL DEPOSIT GAME -->
    <b-modal
      id="deposit"
      title=""
      hide-footer
      hide-header
      body-class="p-0"
      header-class="p-0"
    >
      <div class="p-4">
        <div class="header-modal mb-2">
          <h3 class="mb-0">{{ $t('stake.depositFromStakeToGame') }}</h3>
          <img
            :src="require('@/assets/images/icons/close-white.svg')"
            alt=""
            @click="$bvModal.hide('deposit')"
            class="close"
          />
        </div>
        <Deposit />
      </div>
    </b-modal>
    <!-- END MODAL DEPOSIT GAME -->
    <!-- MODAL WITHDRAW GAME -->
    <b-modal
      id="withdraw"
      title=""
      hide-footer
      hide-header
      body-class="p-0"
      header-class="p-0"
    >
      <div class="p-4">
        <div class="header-modal mb-2">
          <h3 class="mb-0">Withdraw From Game To Stake</h3>
          <img
            :src="require('@/assets/images/icons/close-white.svg')"
            alt=""
            @click="$bvModal.hide('withdraw')"
            class="close"
          />
        </div>
        <Withdraw />
      </div>
    </b-modal>
    <!-- END MODAL WITHDRAW GAME -->
  </div>
</template>
  
<script>
import Deposit from '@/components/Wallet/Game/Deposit.vue';
import Withdraw from '@/components/Wallet/Game/Withdraw.vue';
import Paginate from '@/components/Shared/Pagination.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'StakeViewPage',
  components: {
    Paginate,
    Deposit,
    Withdraw,
  },
  data() {
    return {
      amountStake: 0,
      widthL: 0,
      showTableMobile: false,
      activeTab: 'my-stake',
      idPackageChoose: null,
      amountUpgrade: 0,
    };
  },
  computed: {
    ...mapGetters({
      StatisticStake: 'stake/StatisticStake',
      ListStake: 'stake/ListStake',
      UserBalance: 'userinfo/UserBalance',
      HistoryStake: 'stake/HistoryStake',
      HistoryBonus: 'stake/HistoryBonus',
    }),

    fieldsInfoPackage() {
      return [
        {
          key: 'package_ID',
          label: 'STT',
          class: 'text-center',
        },
        {
          key: 'package_Name',
          label: this.$t('stake.name'),
          class: 'text-center',
        },
        {
          key: 'package_Description',
          label: this.$t('stake.description'),
          class: 'text-center min-200',
        },
        {
          key: 'package_Price_Min',
          label: this.$t('wallet.min'),
          class: 'text-center',
        },
        {
          key: 'package_Price_Max',
          label: this.$t('stake.max'),
          class: 'text-center',
        },
        {
          key: 'package_Note',
          label: this.$t('stake.percent'),
          class: 'text-center',
        },
        {
          key: 'package',
          label: '',
          class: 'text-center',
        },
      ];
    },

    fieldsHistory() {
      return [
        {
          key: 'Money_ID',
          label: 'ID',
          class: 'text-center',
        },
        {
          key: 'MoneyAction_Name',
          label: this.$t('wallet.action'),
          class: 'text-center',
        },
        {
          key: 'Money_USDT',
          label: this.$t('wallet.amount'),
          class: 'text-center',
        },
        {
          key: 'Money_Comment',
          label: this.$t('wallet.comment'),
          class: 'text-center',
        },
        {
          key: 'Money_Rate',
          label: this.$t('wallet.rate'),
          class: 'text-center',
        },
        {
          key: 'Money_USDTFee',
          label: this.$t('wallet.fee'),
          class: 'text-center',
        },
        {
          key: 'Money_Time',
          label: this.$t('wallet.time'),
          class: 'text-center',
        },
      ];
    },

    fieldsStake() {
      return [
        {
          key: 'investment_ID',
          label: this.$t('stake.code'),
          class: 'text-center',
        },
        {
          key: 'investment_Total',
          label: this.$t('wallet.amount'),
          class: 'text-center',
        },
        {
          key: 'investment_Bonus',
          label: this.$t('stake.bonus'),
          class: 'text-center',
        },
        {
          key: 'investment_Expired',
          label: this.$t('community.expired'),
          class: 'text-center',
        },
        {
          key: 'investment_Rate',
          label: this.$t('wallet.rate'),
          class: 'text-center',
        },
        {
          key: 'investment_Time',
          label: this.$t('wallet.time'),
          class: 'text-center',
        },
        {
          key: 'investment_Status',
          label: this.$t('wallet.status'),
          class: 'text-center',
        },
        {
          key: 'action',
          label: this.$t('wallet.action'),
          class: 'text-center',
        },
      ];
    },

    fieldsBonus() {
      return [
        {
          key: 'Log_ID',
          label: 'ID ' + this.$t('stake.bonus'),
          class: 'text-center',
        },
        {
          key: 'Log_Action',
          label: this.$t('wallet.action'),
          class: 'text-center',
        },
        {
          key: 'Log_Amount',
          label: this.$t('wallet.amount'),
          class: 'text-center',
        },
        {
          key: 'Log_Comment',
          label: this.$t('wallet.comment'),
          class: 'text-center',
        },
        {
          key: 'Log_CreatedAt',
          label: this.$t('wallet.time'),
          class: 'text-center',
        },
        {
          key: 'Log_Status',
          label: this.$t('wallet.status'),
          class: 'text-center',
        },
      ];
    },

    ListPackage() {
      if (this.StatisticStake) {
        return this.StatisticStake.packageList;
      }
      return null;
    },

    PackageUser() {
      if (this.StatisticStake && this.StatisticStake.packageUser) {
        return this.StatisticStake.packageUser;
      }
      return null;
    },

    UserStake() {
      if (this.StatisticStake && this.StatisticStake.history_invest) {
        return [this.StatisticStake.history_invest];
      }
      return [];
    },

    HistoryProfitOrGameShow() {
      if (
        this.HistoryStake &&
        this.HistoryStake.data &&
        this.HistoryStake.data.length > 0
      ) {
        return this.HistoryStake.data;
      }
      return [];
    },
  },
  watch: {
    StatisticStake: {
      handler(newVal) {
        if (newVal) {
          this.amountStake = parseFloat(
            newVal.packageList[0].package_Price_Min
          );
        }
      },
    },
    amountStake: {
      handler(newVal) {
        if (newVal) {
          if (newVal && +newVal !== 0 && +newVal > 0) {
            const amountSplit = newVal.toString().split('.');
            if (amountSplit[1] && amountSplit[1].length > 2) {
              this.amountStake =
                this.enterTheNumberAfterTheComma2Numbers(amountSplit);
            }
          }
        }
      },
      deep: true,
    },
    amountUpgrade: {
      handler(newVal) {
        if (newVal) {
          if (newVal && +newVal !== 0 && +newVal > 0) {
            const amountSplit = newVal.toString().split('.');
            if (amountSplit[1] && amountSplit[1].length > 2) {
              this.amountUpgrade =
                this.enterTheNumberAfterTheComma2Numbers(amountSplit);
            }
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    onChangePageListStake(payload) {
      this.$store.dispatch('stake/req_getListStake', { page: payload });
    },
    onChangePageHistoryBonus(payload) {
      this.$store.dispatch('stake/req_getHistoryBonus', { page: payload });
    },
    onChangePageHistory(payload) {
      if (this.activeTab === 'profit') {
        this.$store.dispatch('stake/req_getHistoryStake', {
          page: payload,
          type: 'profit',
        });
      }
      if (this.activeTab === 'game') {
        this.$store.dispatch('stake/req_getHistoryStake', {
          page: payload,
          type: 'game',
        });
      }
    },
    checkResizeWindow() {
      this.widthL = window.innerWidth;
    },
    onStake() {
      this.$store
        .dispatch('stake/req_postStake', { amount: this.amountStake })
        .then(() => {
          this.amountStake = parseFloat(
            this.StatisticStake.packageList[0].package_Price_Min
          );
          this.$bvModal.hide('confirm-stake');
        });
    },
    checkMinAmount() {
      if (
        +this.amountStake <=
        this.StatisticStake.packageList[0].package_Price_Min
      ) {
        this.amountStake = parseFloat(
          this.StatisticStake.packageList[0].package_Price_Min
        );
      }
    },
    onShowModalUpgradePackage(id) {
      this.idPackageChoose = id;
      this.$bvModal.show('upgrade-package');
    },
    onUpgradePackage() {
      this.$store
        .dispatch('stake/req_postUpgradePackage', {
          amount: this.amountUpgrade,
          investment_id: this.idPackageChoose,
        })
        .then(() => {
          this.$bvModal.hide('upgrade-package');
        });
    },
    onActiveTab(type) {
      this.activeTab = type;
      if (type === 'my-stake') {
        this.$store.dispatch('stake/req_getStatisticStake');
      }
      if (type === 'stake') {
        this.$store.dispatch('stake/req_getListStake', { page: 1 });
      }
      if (type === 'profit') {
        this.$store.dispatch('stake/req_getHistoryStake', {
          page: 1,
          type: 'profit',
        });
      }
      if (type === 'game') {
        this.$store.dispatch('stake/req_getHistoryStake', {
          page: 1,
          type: 'game',
        });
      }
      if (type === 'bonus') {
        this.$store.dispatch('stake/req_getHistoryBonus', {
          page: 1,
        });
      }
    },
  },
  mounted() {
    this.checkResizeWindow();
    this.windowEvent = () => {
      this.checkResizeWindow();
    };
    if (this.widthL <= 576) {
      this.showTableMobile = true;
    } else {
      this.showTableMobile = false;
    }
  },
  created() {
    this.$store.dispatch('stake/req_getStatisticStake');
    this.$store.dispatch('stake/req_getListStake', { page: 1 });
  },
};
</script>
  
<style lang="scss" scoped>
.btn-upgrade {
  width: 100%;
  height: 40px;
  background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%) !important;
  border-radius: 3px;
  position: relative;
  z-index: 1;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
    filter: blur(21px);
    z-index: -1;
  }
}

.stake {
  padding: 30px 50px;

  @media (max-width: 992px) {
    padding: 30px 20px;
  }

  @media (max-width: 576px) {
    padding: 30px 10px;
  }

  .stake-statistic {
    align-items: center;
    @media (max-width: 992px) {
      flex-wrap: nowrap;
      overflow-x: auto;
    }

    @media (max-width: 576px) {
      padding: 0px 10px;
      margin: 0px 0px 40px;
    }

    .stake-statistic-item {
      padding: 20px 20px 40px;
      background: #081b3c;
      border-radius: 8px;

      @media (max-width: 376px) {
        padding: 20px 10px;
      }

      p {
        color: #bebebe;
        font-size: 18px;
        font-weight: 400;

        @media (max-width: 1400px) {
          font-size: 16px;
        }

        @media (max-width: 576px) {
          font-size: 14px;
        }
      }

      hr {
        border: 1px solid #404040;
        margin-bottom: 30px;
      }

      .amount {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 30px;
          margin-bottom: 0px;
          background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-weight: bold;

          @media (max-width: 1400px) {
            font-size: 22px;
          }

          @media (max-width: 576px) {
            font-size: 18px;
          }
        }

        img {
          width: 30px;

          @media (max-width: 1400px) {
            width: 25px;
          }
        }
      }
    }
  }

  .stake-level {
    h3 {
      background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-size: 30px;
      font-weight: bold;

      @media (max-width: 1500px) {
        font-size: 22px;
      }

      @media (max-width: 576px) {
        font-size: 18px;
      }
    }

    .stake-level-item {
      padding: 20px;
      background: #081b3c;
      border-radius: 8px;
      height: 100%;

      &.package {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        position: relative;

        .notification {
          position: absolute;
          top: 5px;
          right: 10px;
          cursor: pointer;
        }

        .stake-level-item-img {
          width: 30%;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 576px) {
            width: 100%;
            margin-bottom: 20px;
          }

          // img {
          //     @media (max-width: 1300px) {
          //         width: 100%;
          //     }
          // }
        }

        .stake-level-item-info {
          width: 68%;

          @media (max-width: 576px) {
            width: 100%;
            text-align: center;
          }

          .duration {
            color: #878787;
            font-size: 18px;

            @media (max-width: 1500px) {
              font-size: 16px;
            }
          }

          .box-pending {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 376px) {
              flex-direction: column;
            }

            p {
              color: #ffffff;
              margin-bottom: 0px;
              display: flex;
              align-items: center;
              font-size: 20px;
              line-height: 100%;

              @media (max-width: 1500px) {
                font-size: 16px;
              }

              @media (max-width: 576px) {
                font-size: 14px;
              }

              @media (max-width: 376px) {
                margin-bottom: 0px;
              }

              img {
                margin-right: 10px;

                @media (max-width: 1500px) {
                  margin-right: 5px;
                }
              }

              &.amount {
                margin-top: 20px;
                background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-size: 22px;

                @media (max-width: 1500px) {
                  font-size: 18px;
                }

                @media (max-width: 376px) {
                  margin-top: 0px;
                }
              }
            }

            .box-pending-stake {
              @media (max-width: 376px) {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
              }
            }

            .line {
              width: 1px;
              height: 50px;
              background: #5db8fb;

              @media (max-width: 376px) {
                display: none;
              }
            }

            .box-pending-reward {
              @media (max-width: 376px) {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }

          .btn-recharge {
            width: 180px;
            height: 50px;
            background: linear-gradient(
              91.46deg,
              #0ec5ff 1.08%,
              #3964fb 83%
            ) !important;
            border-radius: 3px;
            position: relative;
            z-index: 1;
            font-weight: bold;
            font-size: 20px;
            margin: 30px 10px 0px;

            @media (max-width: 1500px) {
              font-size: 16px;
            }

            @media (max-width: 576px) {
              font-size: 16px;
            }

            &::before {
              position: absolute;
              content: '';
              top: 0;
              left: 0;
              width: 100%;
              height: 50px;
              background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
              filter: blur(21px);
              z-index: -1;
            }
          }
        }
      }

      &.form-stake {
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;

          .minimum {
            color: #8a8a8a;
            margin-bottom: 0px;

            @media (max-width: 576px) {
              font-size: 14px;
            }

            span {
              background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              font-size: 20px;
              font-weight: bold;

              @media (max-width: 576px) {
                font-size: 16px;
              }
            }
          }

          .amount {
            margin-bottom: 0px;

            @media (max-width: 576px) {
              font-size: 14px;
            }
          }
        }

        .box-search {
          position: relative;

          p {
            margin-bottom: 5px;

            @media (max-width: 576px) {
              font-size: 14px;
            }
          }

          .custom-input {
            height: 50px;

            @media (max-width: 576px) {
              height: 40px;
            }
          }

          button {
            position: absolute;
            top: 45px;
            right: 15px;
            display: flex;

            @media (max-width: 576px) {
              top: 37px;
              font-size: 14px;
            }

            .line {
              width: 1px;
              height: 20px;
              background: #5db8fb;
              display: block;
              margin: 0 5px;
            }

            span {
              background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
        }

        .btn-stake {
          margin-top: 30px;
          width: 100%;
          height: 50px;
          background: linear-gradient(
            91.46deg,
            #0ec5ff 1.08%,
            #3964fb 83%
          ) !important;
          border-radius: 3px;
          position: relative;
          z-index: 1;
          font-weight: bold;
          font-size: 20px;

          @media (max-width: 576px) {
            font-size: 16px;
          }

          &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 50px;
            background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
            filter: blur(21px);
            z-index: -1;
          }
        }
      }
    }
  }

  .stake-history {
    margin-top: 50px;

    .title {
      font-size: 30px;
      background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 20px;

      @media (max-width: 576px) {
        font-size: 22px;
      }
    }

    .stake-history-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      @media (max-width: 440px) {
        flex-direction: column;
      }

      .group-search {
        display: flex;
        align-items: center;

        @media (max-width: 440px) {
          margin-top: 20px;
        }

        .custom-input {
          width: 200px;
          height: 40px;

          @media (max-width: 992px) {
            font-size: 12px !important;
            width: 140px;
            height: 30px;
          }
        }
      }
    }

    .historyTable {
      background: linear-gradient(
        180deg,
        #081e43 0%,
        rgba(1, 6, 15, 0.63) 100%
      );
      border-radius: 4px;
      padding: 20px;

      .stake-tab {
        padding: 5px;
        background: rgba(27, 72, 187, 0.2);
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(34px);
        border-radius: 7px;
        width: fit-content;
        max-width: 100%;
        margin-bottom: 40px;
        overflow-x: auto;
        overflow-y: hidden;

        .listTab {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .tab-item {
            padding: 0px 25px;
            height: 55px;

            @media (max-width: 1400px) {
              font-size: 14px;
              height: 35px;
            }

            @media (max-width: 768px) {
              min-width: 150px;
            }

            &.activeTab {
              background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
              border-radius: 6px;
            }
          }
        }
      }
    }

    .table-mobile {
      width: 100%;
      background: linear-gradient(
        180deg,
        #081e43 0%,
        rgba(1, 6, 15, 0.63) 100%
      );
      border-radius: 4px;
      padding: 20px;

      .header {
        width: 100%;
        background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
        border-radius: 6px;
        padding: 0px 25px;
        height: 55px;
        margin-bottom: 20px;
      }

      .collapse {
        margin-top: -20px !important;
        padding: 20px 10px;
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
        -webkit-backdrop-filter: blur(34px);
        backdrop-filter: blur(34px);
        border-radius: 7px;
        margin-bottom: 20px;

        P {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.table-mobile {
  width: 100%;
  background: linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);
  border-radius: 4px;
  padding: 20px;

  .header {
    width: 100%;
    background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
    border-radius: 6px;
    padding: 0px 25px;
    height: 55px;
    margin-bottom: 20px;
  }

  .collapse {
    margin-top: -20px !important;
    padding: 20px 10px;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
    -webkit-backdrop-filter: blur(34px);
    backdrop-filter: blur(34px);
    border-radius: 7px;
    margin-bottom: 20px;

    P {
      margin-bottom: 0px;
    }
  }
}
</style>
<style lang="scss">
#upgrade-package {
  .custom-input {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
  }
}
</style>