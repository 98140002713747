<template>
  <div class="withdraw">
    <h3 class="title">{{ $t("wallet.withdraw") }}</h3>
    <p class="sub-title">
      {{ $t("wallet.sub_title_withdraw_game") }}
    </p>
    <hr />
    <b-row class="withdraw-content" v-if="netWorkChoice">
      <b-col xl="12">
        <p class="labelInput">
          {{ $t("wallet.amount") }} <br />

          <!-- <span class="text-danger" v-if="netWorkChoice.name === 'SBS'"
            >(Note: You can only withdraw the difference when the volume is 10
            times the amount deposited from stake through the game)
          </span> -->
        </p>
        <div class="form-group">
          <input
            v-if="netWorkChoice.name === 'SBS'"
            type="number"
            :value="amountMax"
            readonly
            class="form-control custom-input"
          />
          <input
            v-else
            type="number"
            v-model="amount"
            class="form-control custom-input"
          />
          <div
            class="box-network"
            @click="showDropdownNetWork = !showDropdownNetWork"
          >
            <img
              :src="
                require(`@/assets/images/currency/${netWorkChoice.name.toLowerCase()}.png`)
              "
              alt=""
              class="mr-1"
              width="25px"
            />
            <p>
              {{ netWorkChoice.name }} <br />
              <span>{{
                TruncateToDecimals2(+netWorkChoice.amount, "", 2)
              }}</span>
            </p>
            <img
              src="~@/assets/images/icons/gold-arrow-down.svg"
              alt="down"
              class="ml-1"
            />
            <div
              class="box-network-dropdown"
              v-if="
                showDropdownNetWork &&
                ListBalanceCanWithdraw &&
                ListBalanceCanWithdraw.length > 0
              "
            >
              <p
                class="text-center"
                v-for="balance in ListBalanceCanWithdraw"
                :key="balance.name"
                @click.stop="onChoiceNetWork(balance)"
              >
                {{ balance.name }} <br />

                <span> {{ TruncateToDecimals2(+balance.amount, "", 2) }}</span>
                <img
                  src="~@/assets/images/icons/selected-balance.png"
                  alt=""
                  class="ml-1"
                  width="10px"
                  v-if="balance.name === netWorkChoice.name"
                />
              </p>
            </div>
          </div>
        </div>
        <div class="predict-list" v-if="showPredict">
          <div class="predict-item" @click="amount = amountPredict.amount1">
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount1, "", 2) }}
          </div>
          <div class="predict-item" @click="amount = amountPredict.amount2">
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount2, "", 2) }}
          </div>
          <div class="predict-item" @click="amount = amountPredict.amount3">
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount3, "", 2) }}
          </div>
          <div class="predict-item" @click="amount = amountPredict.amount4">
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount4, "", 2) }}
          </div>
        </div>
        <p v-if="netWorkChoice.name !== 'SBS'" class="labelInput">
          {{ $t("wallet.min") }}
        </p>
        <div class="form-group">
          <b-form-input
            v-if="netWorkChoice.name !== 'SBS'"
            type="text"
            class="form-control custom-input"
            :value="amountMin"
            readonly
          ></b-form-input>
        </div>
        <div class="box-withdraw-info">
          <div class="withdraw-info-item">
            <div class="withdraw-info">
              <p class="labelInput text-white">{{ $t("wallet.fee") }}:</p>
              <p class="labelInput text-white">0%</p>
            </div>
            <hr />
            <div class="withdraw-info">
              <p class="labelInput text-white">
                {{ $t("wallet.amount_expected_receive") }}:
              </p>
              <p class="labelInput text-white">
                {{ TruncateToDecimals2(+amount, "", 2) }} USD
              </p>
            </div>
          </div>
          <div class="withdraw-info-item">
            <b-button
              class="btnNoStyle btn-withdraw"
              :disabled="
                !amount || +amount < +amountMin || +amount > +amountMax
              "
              @click="$bvModal.show('withdraw-game')"
              >{{ $t("wallet.withdraw") }}</b-button
            >
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Modal Confirm Withdraw -->
    <b-modal id="withdraw-game" hide-footer hide-header v-if="netWorkChoice">
      <div class="header-modal">
        <h3>Withdraw {{ netWorkChoice.name }}</h3>
        <img
          :src="require('@/assets/images/icons/close-white.svg')"
          alt=""
          @click="$bvModal.hide('withdraw-game')"
          class="close"
        />
      </div>
      <p class="text-center mb-0 mt-3">
        Are you sure you want to withdraw
        <span class="font-weight-bold text-warning"> ${{ amount }}</span> from
        <span class="font-weight-bold text-warning"
          >{{ netWorkChoice.name }} balance</span
        >
        to <span class="font-weight-bold text-warning">main balance</span> ?
      </p>
      <div class="form-group text-center d-flex justify-content-center mt-4">
        <b-button
          class="btnNoStyle btn-profitWithdraw"
          type="submit"
          @click="onWithdrawGameToMain(netWorkChoice.name)"
        >
          Withdraw
        </b-button>
      </div>
    </b-modal>
    <!-- End Modal Confirm Withdraw -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WithdrawBalanceMainIntoGamePageView",
  data() {
    return {
      amount: 0,
      showPredict: false,
      amountPredict: {
        amount1: 0,
        amount2: 0,
        amount3: 0,
        amount4: 0,
      },
      netWorkChoice: null,
      showDropdownNetWork: false,
      amountMin: 1,
      amountMax: 1,
    };
  },
  computed: {
    ...mapGetters({
      Price: "wallet/Price",
      UserBalance: "userinfo/UserBalance",
      StatisticWithdrawSBS: "wallet/StatisticWithdrawSBS",
      StatisticStake: "stake/StatisticStake",
    }),
    ListBalanceCanWithdraw() {
      const arr = [];
      arr.push({ name: "BS", amount: this.UserBalance.BSBalance || 0 });
      arr.push({
        name: "SBS",
        amount: this.UserBalance.SBSBalance || 0,
      });
      return arr;
    },
  },
  watch: {
    amount: {
      handler(newVal) {
        if (newVal && +newVal !== 0) {
          console.log(newVal);
          if (this.netWorkChoice.name !== "SBS") {
            this.showPredict = true;
            this.amountPredict = {
              amount1: this.amount * 10,
              amount2: this.amount * 100,
              amount3: this.amount * 1000,
              amount4: this.amount * 10000,
            };
            // --------------------
            const amountSplit = newVal.toString().split(".");
            if (amountSplit[1] && amountSplit[1].length > 2) {
              this.amount =
                this.enterTheNumberAfterTheComma2Numbers(amountSplit);
            }
          } else {
            this.showPredict = false;
          }
        } else {
          this.showPredict = false;
        }
      },
      deep: true,
    },
    ListBalanceCanWithdraw: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.netWorkChoice = newVal[0];
        }
      },
    },
    netWorkChoice: {
      handler(newVal) {
        // console.log('newVal.name', newVal.name);
        if (newVal && newVal.name === "SBS") {
          this.showPredict = false;
          if (
            this.StatisticStake &&
            this.StatisticStake.history_invest &&
            parseFloat(
              this.StatisticStake.history_invest.investment_Withdrawal_Milestone
            ) > 0 &&
            parseFloat(this.UserBalance.SBSBalance) -
              parseFloat(
                this.StatisticStake.history_invest
                  .investment_Withdrawal_Milestone
              ) >
              0
          ) {
            this.amountMax =
              parseFloat(this.UserBalance.SBSBalance) -
              parseFloat(
                this.StatisticStake.history_invest
                  .investment_Withdrawal_Milestone
              );

            this.amount =
              parseFloat(this.UserBalance.SBSBalance) -
              parseFloat(
                this.StatisticStake.history_invest
                  .investment_Withdrawal_Milestone
              );
          } else {
            this.amountMin = 0;
            this.amount = 0;
            this.amountMax = 0;
          }
        } else {
          this.amountMax = this.UserBalance.BSBalance;
          this.amountMin = 1;
          this.amount = 1;
        }
      },
    },
  },
  methods: {
    onChoiceNetWork(balance) {
      this.showDropdownNetWork = false;
      this.netWorkChoice = balance;
    },
    onWithdrawGameToMain(type) {
      if (type === "SBS") {
        this.$store
          .dispatch("wallet/req_postWithdrawFromGameStakeToMain", {
            amount: this.amountMax,
          })
          .then(() => {
            this.$bvModal.hide("withdraw-game");
          });
      }
      if (type === "BS") {
        this.$store
          .dispatch("wallet/req_postWithdrawFromGameToMain", {
            amount: this.amount,
          })
          .then(() => {
            this.$bvModal.hide("withdraw-game");
          });
      }
    },
  },
  created() {
    this.$store.dispatch("wallet/req_getStatisticWithdrawSBS");
    this.$store.dispatch("userinfo/req_getBalance");
  },
};
</script>

<style lang="scss" scoped>
.withdraw {
  .title {
    background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 23px;

    @media (max-width: 1400px) {
      font-size: 20px;
    }
  }

  .sub-title {
    color: #bebebe;
    font-size: 16px;

    @media (max-width: 1400px) {
      font-size: 14px;
    }
  }

  hr {
    border: 1px solid rgba(93, 184, 251, 0.42);
  }

  .withdraw-content {
    align-items: center;

    .labelInput {
      margin-bottom: 5px;
      color: #616161;
      font-weight: 600;

      @media (max-width: 1400px) {
        font-size: 14px;
      }

      span {
        background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-size: 14px;
      }
    }

    .form-group {
      display: flex;
      align-items: center;
      height: 50px;

      input {
        height: 100%;
      }

      .box-network {
        height: 100%;
        margin-left: 10px;
        border: 1px solid #797979;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        cursor: pointer;
        position: relative;

        p {
          margin-bottom: 0px;
          line-height: 100%;
          width: fit-content;
          text-align: center;

          @media (max-width: 1400px) {
            font-size: 14px;
          }

          span {
            background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-weight: bold;
          }
        }

        .box-network-dropdown {
          position: absolute;
          bottom: -95px;
          left: 0px;
          width: 100%;
          border: 1px solid #797979;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background: #1b2c57;
          z-index: 100;

          p {
            padding: 5px 0px;
          }
        }
      }
    }

    .predict-list {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      overflow-x: auto;
      padding-bottom: 10px;

      .predict-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 150px;
        flex-shrink: 0;
        border: 1px solid rgba(93, 184, 251, 0.42);
        border-radius: 5px;
        text-align: center;
        height: 30px;
        cursor: pointer;
        padding: 0px 5px;
        margin: 0px 5px;

        &:hover {
          background: rgba(93, 184, 251, 0.42);
        }
      }
    }

    .box-withdraw-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 576px) {
        flex-direction: column;
      }

      .withdraw-info-item {
        width: 48%;
        margin-top: 20px;

        @media (max-width: 576px) {
          width: 100%;
        }

        .withdraw-info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            margin-bottom: 0px;
          }
        }

        .btn-withdraw {
          width: 100%;
          height: 50px;
          background: linear-gradient(
            91.46deg,
            #0ec5ff 1.08%,
            #3964fb 83%
          ) !important;
          border-radius: 3px;
          position: relative;
          z-index: 1;

          &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 50px;
            background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
            filter: blur(21px);
            z-index: -1;
          }
        }
      }
    }

    .note {
      margin-top: 30px;
      font-size: 14px;

      span {
        background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .withdraw-qr {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .withdraw-qr-address {
        width: 100%;
        background: #1b2c57;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
        display: flex;
        margin-top: 15px;
        padding: 15px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: #bebebe;
          margin-bottom: 0px;

          &.copy {
            color: #ffffff;
          }
        }

        .line {
          width: 1px;
          height: 20px;
          margin-left: 10px;
          background-color: #bebebe;
        }

        img {
          margin: 0px 10px;
        }
      }
    }
  }
}

.btn-profitWithdraw {
  width: 100%;
  height: 50px;
  background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
  border-radius: 3px;
  position: relative;
  z-index: 1;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
    filter: blur(21px);
    z-index: -1;
  }
}
</style>
